import { useStaticQuery, graphql } from "gatsby"

const useForgeQuery = () => {
  const forgeQuery = useStaticQuery(graphql`
    query forgeQuery {
      allFile(
        filter: {
          extension: { eq: "jpg" }
          name: {
            in: [
              "A7S03805_Easy-Resize.com_-1024x684-1"
              "A7S03665_Easy-Resize.com_"
              "IMG_20200124_132436-scaled-1"
            ]
          }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  return forgeQuery
}

export default useForgeQuery
