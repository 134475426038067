import React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Head"
import TalentsForgeComponent from "../components/Sections/TalentsForgePage"

const TalentsForge = () => {
  return (
    <Layout>
      <Head
        description="👨‍🎓💻 Talents Forge was created to introduce young programmers ⚙ into the IT world 🌐 Do you want to learn real programming? Join us ✅"
        pageTitle="Talents Forge | Gain Real Programming Experience"
        keywords="software, oakfusion"
      />
      <TalentsForgeComponent />
    </Layout>
  )
}

export default TalentsForge
