import codeIcon from "@iconify-icons/fa-solid/code"
import roundBusinessCenter from "@iconify-icons/ic/round-business-center"
import personSettings16Filled from "@iconify-icons/fluent/person-settings-16-filled"
import peopleTeam24Filled from "@iconify-icons/fluent/people-team-24-filled"
import userAstronaut from "@iconify-icons/fa-solid/user-astronaut"

const forgeListItemData = [
  {
    id: 1,
    text: "get free programming knowledge",
    icon: codeIcon,
  },
  {
    id: 2,
    text: "gain real experience",
    icon: roundBusinessCenter,
  },
  {
    id: 3,
    text: "improve soft skills",
    icon: personSettings16Filled,
  },
  {
    id: 4,
    text: "become a better team player",
    icon: peopleTeam24Filled,
  },
  {
    id: 5,
    text: "will understand the IT language better",
    icon: userAstronaut,
  },
]

export default forgeListItemData
