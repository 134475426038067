import React from "react"
import { ImageCover, ContentWrapper } from "./style"
import IGridImage from "./types"
import BackgroundImage from "gatsby-background-image"
import { P, H3Title } from "../../../../globalStyle"

const GridImage = (props: IGridImage) => {
  const { title, text, image } = props
  return (
    <BackgroundImage
      style={{ zIndex: 465, margin: "15px" }}
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...image}
      preserveStackingContext
    >
      <ImageCover />
      <ContentWrapper>
        <H3Title>{title}</H3Title>
        <P>{text}</P>
      </ContentWrapper>
    </BackgroundImage>
  )
}

export default GridImage
