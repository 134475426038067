import styled from "styled-components"

export const ImageWrapper = styled.div``

export const ImageCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.orange};
  opacity: 0.6;
`
export const ContentWrapper = styled.div`
  position: relative;
  padding: 30px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > p,
  h3 {
    color: ${props => props.theme.colors.white};
  }
`
