import styled from "styled-components"

export const Container = styled.div`
  width: 1470px;

  @media (max-width: 1470px) {
    width: 100%;
  }
`
export const TextWrapper = styled.div`
  margin-top: 0;
  padding: 0 15px 0 15px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-end;
  margin-bottom: 40px;
`
export const BackgroundWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
export const GridContent = styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 465;
  & > p {
    color: ${props => props.theme.colors.white};
  }
  & > div > p {
    margin: 2px 10px 2px 45px;
    color: ${props => props.theme.colors.white};
  }
  @media (max-width: 500px) {
    padding: 10px;
    & > div > p {
      margin: 2px 10px 2px 15px;
    }
  }
`

export const GridImage = styled.div``

export const Cover = styled.div`
  position: absolute;
  width: 100%;
  height: 15%;
  background-color: white;
  left: 0;
  z-index: 455;
  @media (max-width: 1050px) {
    height: 0;
  }
`

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  & > h2 {
    margin-bottom: 0;
  }
`
