const forgeData = [
  {
    id: 1,
    title: "How Long Does The Course Take?",
    text:
      "We estimate that it takes about six months for the programmer to be able to independently work in IT, although some of the students are ready for it in just a few weeks.",
  },
  {
    id: 2,
    title: "How Does The Recruitment Process Look?",
    text:
      "We choose people who are motivated, passionate and positive, ... who have basic theoretical knowledge and are able to write code on their own.",
  },
  {
    id: 3,
    title: "How Big Is The Team?",
    text:
      "GWe work in the teams not bigger than 6 people, chaired by our code architect and product owner. Our experience shows, that it’s the most accurate number for keeping good working speed and proper communication.",
  },
]

export default forgeData
