import React from "react"
import {
  Container,
  TextWrapper,
  ButtonWrapper,
  BackgroundWrapper,
  GridContent,
  Cover,
} from "./style"
import {
  H2Title,
  P,
  Section,
  GrayBorder,
  DoubleGrid,
  AnimatedBackground,
  OrangeSpan,
} from "../../../globalStyle"
import Button from "../../UI/Button"
import bxEdit from "@iconify-icons/bx/bx-edit"
import GridImage from "./GridImage"
import forgeData from "../../../data/components/forge"
import useForgeQuery from "../../../helpers/queries/forgeQuery"
import { convertToBgImage } from "gbimage-bridge"
import QuickContact from "../QuickContact"
import forgeListItemData from "../../../data/components/forgeListItem"

import ListItem from "../../UI/ListItem"

const TalentsForgeComponent = () => {
  const forgeImagesData = useForgeQuery()
  return (
    <Section style={{ marginTop: "100px" }}>
      <Container>
        <H2Title marginLeft="15px">Talents Forge</H2Title>
      </Container>
      <GrayBorder />
      <Container>
        <DoubleGrid>
          <TextWrapper>
            <P>
              Talents Forge was created to introduce young programmers into the
              IT world and test them against challenges that professional face
              every day. It’s not just an ordinary programming course, but a
              place where future developers work on a real IT projects.
            </P>
          </TextWrapper>
          <ButtonWrapper>
            <Button
              slug="https://drive.google.com/file/d/14jOq3OXvygD4l8Kziae-d44AEiVA_TSj/view?usp=sharing"
              icon={bxEdit}
            >
              Regulations
            </Button>
          </ButtonWrapper>
        </DoubleGrid>
      </Container>
      <BackgroundWrapper>
        <AnimatedBackground></AnimatedBackground>
        <Cover style={{ top: 0 }} />
        <Cover style={{ bottom: 0 }} />
        <Container>
          <DoubleGrid>
            <GridContent>
              <P>By participating in Talents Forge you:</P>
              <div>
                {forgeListItemData.map(i => (
                  <ListItem key={i.id} icon={i.icon}>
                    {i.text}
                  </ListItem>
                ))}
              </div>
              <P>
                In each project you will be working in agile methodology and
                using tools like Java, Java SpringBoot, React/Angular and
                DevOps.
              </P>
            </GridContent>
            <GridContent>
              {forgeData.map(c => (
                <GridImage
                  key={c.id}
                  image={convertToBgImage(
                    forgeImagesData.allFile.edges[c.id - 1].node.childImageSharp
                      .gatsbyImageData
                  )}
                  text={c.text}
                  title={c.title}
                />
              ))}
            </GridContent>
          </DoubleGrid>
        </Container>
      </BackgroundWrapper>
      <Container>
        <QuickContact
          title={
            <>
              Start an <OrangeSpan>oak adventure</OrangeSpan>
            </>
          }
          button={<Button slug="/contact">Contact</Button>}
          text={" Are you ambitious and willing to learn real programming?"}
        />
      </Container>
    </Section>
  )
}

export default TalentsForgeComponent
